import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

export const HabourMasterHeader: React.FC = () => (
  <h1>
    <Link component={RouterLink} to="/">
      🚢 Nym Harbour Master
    </Link>
  </h1>
);
