import { getLabel } from '../internals/getLabel';
const legendGetter = params => {
  const {
    seriesOrder,
    series
  } = params;
  return seriesOrder.reduce((acc, seriesId) => {
    series[seriesId].data.forEach(item => {
      const formattedLabel = getLabel(item.label, 'legend');
      if (formattedLabel === undefined) {
        return;
      }
      acc.push({
        color: item.color,
        label: formattedLabel,
        id: item.id
      });
    });
    return acc;
  }, []);
};
export default legendGetter;