const axisExtremumCallback = (acc, chartType, axis, getters, isDefaultAxis, formattedSeries) => {
  const getter = getters[chartType];
  const series = formattedSeries[chartType]?.series ?? {};
  const [minChartTypeData, maxChartTypeData] = getter?.({
    series,
    axis,
    isDefaultAxis
  }) ?? [null, null];
  const [minData, maxData] = acc;
  if (minData === null || maxData === null) {
    return [minChartTypeData, maxChartTypeData];
  }
  if (minChartTypeData === null || maxChartTypeData === null) {
    return [minData, maxData];
  }
  return [Math.min(minChartTypeData, minData), Math.max(maxChartTypeData, maxData)];
};
export const getAxisExtremum = (axis, getters, isDefaultAxis, formattedSeries) => {
  const charTypes = Object.keys(getters);
  return charTypes.reduce((acc, charType) => axisExtremumCallback(acc, charType, axis, getters, isDefaultAxis, formattedSeries), [null, null]);
};