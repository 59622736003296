import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Ansi from '@cocalc/ansi-to-react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import { getProbeScoreEmoji, getConfigScoreEmoji, configCategoryItems } from './Gateways';
import { HabourMasterHeader } from './Logo';
import { api } from './api';
import { type GatewayScore } from './types';
import { GatewayTestResults } from './GatewayTestResults';

const boolToEmoji = (value?: boolean) => (value ? '✅' : '❌');
export const GatewayScoreList: React.FC<{ gatewayScore: GatewayScore }> = ({ gatewayScore }) => (
  <Stack direction="row" spacing={2} mb={2}>
    <Box>
      <Stack direction="row" spacing={4} my={2}>
        <h3>Config score</h3>
        <h3>{getConfigScoreEmoji(gatewayScore.configScore)}</h3>
      </Stack>
      <TableContainer sx={{ maxWidth: 400 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.entryGateway}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.runsEntryGateway)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.exitGatewayWithIPR}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.runsIPR)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.exitGatewayWithSocks5}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.runsNetworkRequester)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.exitPolicyEnabled}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.usesExitPolicy)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.runsMinVersion}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.minBuildVersion)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>{configCategoryItems.runsAuthentictorServiceProvider}</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.configScoreItems.runsAuthenticatorServiceProvider)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    <Box>
      <Stack direction="row" spacing={4} my={2}>
        <h3>Probe score</h3>
        <h3>{getProbeScoreEmoji(gatewayScore.probeScore)}</h3>
      </Stack>
      <TableContainer sx={{ maxWidth: 400 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>
                ↗️ Entry Gateway <strong>(Mixnet mode)</strong>
              </TableCell>
              <TableCell />
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can connect?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_entry?.can_connect)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can route?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_entry?.can_route)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 3 }}>
                ⚛️ Entry Gateway <strong>(Wireguard mode)</strong>
              </TableCell>
              <TableCell />
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can register Wireguard peers?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.wg?.can_register)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can complete Wireguard handshake?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.wg?.can_handshake)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Resolves DNS when Wireguard active?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.wg?.can_resolve_dns)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Ping hosts performance ok?</TableCell>
              <TableCell>
                {boolToEmoji((gatewayScore.probeScoreItems?.wg?.ping_hosts_performance || 0) > 0.75)}&nbsp;(
                {Math.round((gatewayScore.probeScoreItems?.wg?.ping_hosts_performance || 0) * 100)}%)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Ping IP performance ok?</TableCell>
              <TableCell>
                {boolToEmoji((gatewayScore.probeScoreItems?.wg?.ping_ips_performance || 0) > 0.75)}&nbsp;(
                {Math.round((gatewayScore.probeScoreItems?.wg?.ping_ips_performance || 0) * 100)}%)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 3 }}>↘️ Exit Gateway</TableCell>
              <TableCell />
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can connect?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_exit?.can_connect)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can route IPV4 on localhost?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_exit?.can_route_ip_v4)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can route IPV6 on localhost?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_exit?.can_route_ip_v6)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can route IPV4 to the internet?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_exit?.can_route_ip_external_v4)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ pl: 6 }}>Can route IPV6 to the internet?</TableCell>
              <TableCell>{boolToEmoji(gatewayScore.probeScoreItems?.as_exit?.can_route_ip_external_v6)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Stack>
);

export const Gateway: React.FC = () => {
  const { gatewayIdentityKey } = useParams();
  const [gateway, setGateway] = React.useState<any>();
  const [error, setError] = React.useState<string>();

  const refreshGateway = async () => {
    try {
      if (gatewayIdentityKey) {
        const newGateway = await api.gateway(gatewayIdentityKey);
        setGateway(newGateway);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
      setError(e.message);
    }
  };

  React.useEffect(() => {
    refreshGateway();
    const timer = setInterval(refreshGateway, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, [gatewayIdentityKey]);

  if (error) {
    return (
      <Box p={4}>
        <HabourMasterHeader />
        {gatewayIdentityKey && (
          <h2>
            ⛩️ Gateway <code>{gatewayIdentityKey}</code>
          </h2>
        )}
        <Typography>Oh no! Something went wrong: {error}</Typography>
      </Box>
    );
  }

  if (!gateway) {
    return (
      <Box p={4}>
        <HabourMasterHeader />
        {gatewayIdentityKey && (
          <h2>
            ⛩️ Gateway <code>{gatewayIdentityKey}</code>
          </h2>
        )}
        <Box mt={4} display="flex" alignItems="center">
          <CircularProgress />
          <Typography fontSize="larger" ml={2}>
            Please have some popcorn 🍿 or peanuts 🥜 while we cook your order...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <HabourMasterHeader />
      <h2>
        ⛩️ Gateway <code>{gatewayIdentityKey}</code>
      </h2>

      <Box>
        Last updated from directory&nbsp;
        <strong>{formatDistanceToNow(gateway.last_updated_utc, { addSuffix: true })}</strong>
        &nbsp;at&nbsp;
        {gateway.last_updated_utc.toISOString()}
      </Box>

      <Box>
        <Link href={`https://explorer.nymtech.net/network-components/gateway/${gatewayIdentityKey}`} target="_blank">
          View in explorer
        </Link>
      </Box>

      <hr />

      {gateway.bonded && <h3>{boolToEmoji(gateway.bonded)}</h3>}
      {gateway.blacklisted && <h3>Blacklisted 🔴</h3>}

      {gateway.bonded && !gateway.blacklisted && (
        <>
          <Typography>{(gateway?.explorer_pretty_bond?.pledge_amount.amount || 0) / 1e6} NYM</Typography>
          <Typography mb={4}>
            Role = <code>{gateway?.self_described.role}</code>
          </Typography>
        </>
      )}

      {gateway.blacklisted && (
        <Alert severity="warning" variant="filled">
          <AlertTitle sx={{ fontWeight: 600 }}>Gateway is blacklisted 😢</AlertTitle>
          <Typography>
            Please check the network monitor tests at the end of this page to see what the test results for mixnet mode
            have been recorded for this gateway.
          </Typography>
        </Alert>
      )}

      {!gateway.bonded && !gateway.blacklisted && (
        <Alert severity="error" variant="filled">
          <AlertTitle sx={{ fontWeight: 600 }}>Gateway is no longer bonded 😢</AlertTitle>
          <Typography>
            This gateway is no longer bonded. All data shown is historical from when it was in the directory or last
            probed.
          </Typography>
        </Alert>
      )}

      <GatewayScoreList gatewayScore={gateway.gatewayScore} />

      <hr />

      <h3>
        Last Probe Result ({formatDistanceToNow(gateway.last_testrun_utc, { addSuffix: true })} -{' '}
        {gateway.last_testrun_utc.toISOString()})
      </h3>
      <pre>{JSON.stringify(gateway?.last_probe_result, null, 2)}</pre>

      <h4>Probe Log</h4>
      <pre>
        <Ansi>{gateway?.last_probe_log}</Ansi>
      </pre>

      <h3>Description</h3>
      <pre>{JSON.stringify(gateway?.description, null, 2)}</pre>

      <h3>Self Described</h3>
      <pre>{JSON.stringify(gateway?.self_described, null, 2)}</pre>

      <h3>Explorer API</h3>
      <pre>{JSON.stringify(gateway?.explorer_pretty_bond, null, 2)}</pre>

      {gatewayIdentityKey && <GatewayTestResults identityKey={gatewayIdentityKey} />}
    </Box>
  );
};
