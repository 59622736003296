/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import Flag from 'react-world-flags';
import Typography from '@mui/material/Typography';
import Ansi from '@cocalc/ansi-to-react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link as RouterLink } from 'react-router-dom';
import semverCompare from 'semver/functions/compare-loose';
import { BarChart } from '@mui/x-charts/BarChart';
import { api } from './api';
import { configCategories, minNymNodeVersion, probeCategories } from './scores';
import { HumanDateTime } from './HumanDateTime';
import { SummaryLine } from './SummaryLine';

const CACHE_KEY = 'cache-gateways';

const CustomWidthTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
});

export const configCategoryItems = {
  entryGateway: 'Entry Gateway (mixnet mode)',
  exitGatewayWithIPR: 'Exit Gateway with IPR',
  exitGatewayWithSocks5: 'Exit Gateway with SOCKS5 NR',
  exitPolicyEnabled: 'Exit Policy Enabled',
  runsNymNode: (
    <>
      Runs <code>nym-node</code>
    </>
  ),
  runsMinVersion: (
    <>
      Runs <code>nym-node</code> {'>'} v{minNymNodeVersion}
    </>
  ),
  runsAuthentictorServiceProvider: 'Runs Auth Service Provider',
};

export const getConfigScoreEmoji = (value: number) => {
  if (value >= configCategories.panda.minScore) {
    return configCategories.panda.emoji;
  }
  if (value >= configCategories.dolphin.minScore) {
    return configCategories.dolphin.emoji;
  }
  if (value >= configCategories.fox.minScore) {
    return configCategories.fox.emoji;
  }
  if (value >= configCategories.mouse.minScore) {
    return configCategories.mouse.emoji;
  }
  return <>❌</>;
};

export const getProbeScoreEmoji = (value?: number) => {
  if (!value) {
    return '❌';
  }
  if (value >= probeCategories.peanut.minScore) {
    return probeCategories.peanut.emoji;
  }
  if (value >= probeCategories.icecream.minScore) {
    return probeCategories.icecream.emoji;
  }
  if (value >= probeCategories.popcorn.minScore) {
    return probeCategories.popcorn.emoji;
  }
  if (value >= probeCategories.lollipop.minScore) {
    return probeCategories.lollipop.emoji;
  }
  return '❌';
};

export const Gateways: React.FC = () => {
  const [allGateways, setAllGateways] = React.useState<any[]>([]);
  const [gateways, setGateways] = React.useState<any[]>([]);
  const [gatewayCount, setGatewayCount] = React.useState<number>();
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();
  const [summary, setSummary] = React.useState<any>(null);

  const countCountries = (item: any) =>
    item
      .filter((i: any) => Boolean(i.explorer_pretty_bond?.location?.two_letter_iso_country_code))
      .map((i: any) => i.explorer_pretty_bond.location.two_letter_iso_country_code)
      .reduce((acc: any, cur: any) => {
        if (!acc[cur]) {
          acc[cur] = 1;
        } else {
          acc[cur] += 1;
        }
        return acc;
      }, {});

  React.useEffect(() => {
    if (gateways) {
      const pandas = gateways.filter((g) => g.config_score_calculated >= configCategories.panda.minScore);
      const dolphins = gateways.filter(
        (g) =>
          g.config_score_calculated >= configCategories.dolphin.minScore &&
          g.config_score_calculated < configCategories.panda.minScore,
      );
      const foxes = gateways.filter(
        (g) =>
          g.config_score_calculated >= configCategories.fox.minScore &&
          g.config_score_calculated < configCategories.dolphin.minScore,
      );
      const mouses = gateways.filter(
        (g) =>
          g.config_score_calculated >= configCategories.mouse.minScore &&
          g.config_score_calculated < configCategories.fox.minScore,
      );

      const peanuts = gateways.filter((g) => g.probe_score_calculated >= probeCategories.peanut.minScore);
      const icecreams = gateways.filter(
        (g) =>
          g.probe_score_calculated < probeCategories.peanut.minScore &&
          g.probe_score_calculated >= probeCategories.icecream.minScore,
      );
      const popcorns = gateways.filter(
        (g) =>
          g.probe_score_calculated < probeCategories.icecream.minScore &&
          g.probe_score_calculated >= probeCategories.popcorn.minScore,
      );
      const lollies = gateways.filter((g) => g.probe_score_calculated < probeCategories.popcorn.minScore);

      setSummary({
        termsAndConditions: {
          count: gateways.filter(
            (g) => g.self_described?.auxiliary_details?.accepted_operator_terms_and_conditions === true,
          ).length,
          description: <>✅ Accepted operator terms & conditions</>,
        },
        unbonded: {
          count: allGateways.length ? allGateways.length - gateways.length : 0,
          description: <>Unbonded nodes</>,
        },
        configScores: {
          pandas: {
            count: pandas.length,
            countries: countCountries(pandas),
            description: (
              <>
                ✅ {configCategoryItems.entryGateway}
                <br />✅ {configCategoryItems.exitGatewayWithIPR}
                <br />✅ {configCategoryItems.exitGatewayWithSocks5}
                <br />✅ {configCategoryItems.exitPolicyEnabled}
                <br />✅ {configCategoryItems.runsNymNode}
                <br />✅ {configCategoryItems.runsMinVersion}
                <br />✅ {configCategoryItems.runsAuthentictorServiceProvider}
              </>
            ),
          },
          dolphins: {
            count: dolphins.length,
            countries: countCountries(dolphins),
            description: (
              <>
                ✅ {configCategoryItems.entryGateway}
                <br />✅ {configCategoryItems.exitGatewayWithIPR}
                <br />✅ {configCategoryItems.exitGatewayWithSocks5}
                <br />✅ {configCategoryItems.exitPolicyEnabled}
                <br />✅ {configCategoryItems.runsNymNode}
                <br />✅ {configCategoryItems.runsAuthentictorServiceProvider}
              </>
            ),
          },
          foxes: {
            count: foxes.length,
            countries: countCountries(foxes),
            description: (
              <>
                ✅ {configCategoryItems.entryGateway}
                <br />✅ {configCategoryItems.exitGatewayWithIPR}
                <br />✅ {configCategoryItems.exitGatewayWithSocks5}
                <br />✅ {configCategoryItems.exitPolicyEnabled}
              </>
            ),
          },
          mouses: {
            count: mouses.length,
            countries: countCountries(mouses),
            description: <>✅ {configCategoryItems.entryGateway}</>,
          },
        },
        routingScores: {
          peanuts: {
            count: peanuts.length,
            countries: countCountries(peanuts),
            description: (
              <>
                ↗️ Entry Gateway Connects
                <br />
                🔀 Entry Gateway Routes
                <br />
                ➡️ Exit Gateway Connects
                <br />
                <span style={{ opacity: 0.5 }}>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes internally
                <br />
                <span>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes externally
                <br />
                <span>⚛️</span> Can register Wireguard peers and complete handshake
                <br />
                <span>🔤</span> Resolves DNS when Wireguard active
                <br />
                <span>🛜</span> Wireguard performance &gt; 0.75
              </>
            ),
          },
          icecreams: {
            count: icecreams.length,
            countries: countCountries(icecreams),
            description: (
              <>
                ↗️ Entry Gateway Connects
                <br />
                🔀 Entry Gateway Routes
                <br />
                ➡️ Exit Gateway Connects
                <br />
                <span style={{ opacity: 0.5 }}>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes internally
                <br />
                <span>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes externally
                <br />
                <span>⚛️</span> Can register Wireguard peers and complete handshake
                <br />
                <span>🔤</span> Resolves DNS when Wireguard active
                <br />
                <span style={{ opacity: 0.5 }}>🛜</span> Wireguard performance &lt; 0.75
              </>
            ),
          },
          popcorns: {
            count: popcorns.length,
            countries: countCountries(popcorns),
            description: (
              <>
                ↗️ Entry Gateway Connects
                <br />
                🔀 Entry Gateway Routes
                <br />
                ➡️ Exit Gateway Connects
                <br />
                <span style={{ opacity: 0.5 }}>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes internally
                <br />
                <span>4️⃣️6️⃣</span> Exit Gateway ipv4 and ipv6 routes externally
                <br />
                <span style={{ opacity: 0.2 }}>⚛️</span> Can register Wireguard peers, handshake fails
              </>
            ),
          },
          lollies: {
            count: lollies.length,
            countries: countCountries(lollies),
            description: (
              <>
                ↗️ Entry Gateway Connects
                <br />
                🔀 Entry Gateway Routes
                <br />❌ Exit Gateway does not route ipv4 or ipv6
              </>
            ),
          },
        },
      });
    }
  }, [gateways, allGateways]);

  const gatewayVersions = React.useMemo(() => {
    const versions: any = {
      unknown: 0,
    };
    gateways
      .filter((g) => g.bonded)
      .forEach((g) => {
        const version = g.self_described?.build_information?.build_version;
        if (version) {
          if (!versions[version]) {
            versions[version] = 0;
          }
          versions[version] += 1;
        } else {
          versions.unknown += 1;
        }
      });
    return Object.keys(versions)
      .map((v) => ({ version: v, count: versions[v] }))
      .sort((a, b) => {
        if (a.version === 'unknown' || b.version === 'unknown') {
          return -1;
        }
        return semverCompare(a.version, b.version);
      });
  }, [gateways]);

  const refreshGateways = async () => {
    try {
      let items = await api.gateways();
      setAllGateways(items);
      items = items.filter((g) => g.bonded);
      try {
        window.sessionStorage.setItem(CACHE_KEY, JSON.stringify(items));
      } catch (ex) {
        console.error('Failed to store cache for gateways in session storage', ex);
      }
      setGateways(items);
      setGatewayCount(items.length);
      setBusyMessage('Gateway data updated...');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };

  const refresh = async () => {
    try {
      setBusy(true);
      setBusyMessage('Getting latest data...');
      await Promise.all([refreshGateways()]);
      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    const storedGateways = window.sessionStorage.getItem(CACHE_KEY);
    if (storedGateways?.length) {
      try {
        setGateways(JSON.parse(storedGateways));
        setLastUpdated(new Date());
      } catch (_) {
        // do nothing
      }
    }

    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Identity Key',
        accessorKey: 'gateway_identity_key',
        Cell: ({ cell }) => (
          <Link component={RouterLink} to={`/gateway/${cell.getValue<string>()}`}>
            {cell.getValue<string>().slice(0, 10)}...
          </Link>
        ),
      },
      {
        header: 'Moniker',
        accessorKey: 'description.moniker',
      },
      {
        header: 'Config Score',
        accessorKey: 'config_score_calculated',
        id: 'configScore',
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return getConfigScoreEmoji(value);
        },
      },
      {
        header: 'Probe Score',
        accessorKey: 'probe_score_calculated',
        id: 'probeScoreCalculated',
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return <Typography fontSize="large">{getProbeScoreEmoji(value)}</Typography>;
        },
      },
      {
        header: 'Location',
        accessorKey: 'explorer_pretty_bond.location.two_letter_iso_country_code',
        Cell: ({ cell }) => <Flag code={cell.getValue<string>()} width="20" />,
      },
      {
        header: 'Role',
        accessorKey: 'self_described.role',
        // Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      },
      {
        header: 'Bonded',
        accessorKey: 'bonded',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      },
      {
        header: 'Blacklisted',
        accessorKey: 'blacklisted',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '🔴' : ''),
      },
      {
        header: 'Mixnet Performance',
        accessorKey: 'performance',
        Cell: ({ cell }) => `${cell.getValue<Number>()}%`,
      },
      {
        header: 'Wireguard Performance',
        accessorKey: 'wg_performance',
        Cell: ({ cell }) => `${cell.getValue<Number>()}%`,
      },
      {
        header: 'Accepted Operator T&Cs',
        accessorKey: 'self_described.auxiliary_details.accepted_operator_terms_and_conditions',
        Cell: ({ cell }) => (cell.getValue<Boolean>() ? '✅' : '❌'),
      },
      {
        header: 'Probe result',
        accessorKey: 'last_probe_result',
        Cell: ({ cell }) => {
          const value = cell.getValue<any>();
          const logLines = cell.row.original?.last_probe_log || '';

          const badges = [];
          if (value?.outcome?.as_entry?.can_connect) {
            badges.push(<>↗️</>);
          }
          if (value?.outcome?.as_entry?.can_route) {
            badges.push(<>🔀</>);
          }
          if (value?.outcome?.as_exit?.can_connect) {
            badges.push(<>➡️</>);
          }
          if (value?.outcome?.as_exit?.can_route_ip_v4) {
            badges.push(<span style={{ opacity: 0.5 }}>4️⃣</span>);
          }
          if (value?.outcome?.as_exit?.can_route_ip_v6) {
            badges.push(<span style={{ opacity: 0.5 }}>6️⃣</span>);
          }
          if (value?.outcome?.as_exit?.can_route_ip_external_v4) {
            badges.push(<>4️⃣</>);
          }
          if (value?.outcome?.as_exit?.can_route_ip_external_v6) {
            badges.push(<>6️⃣</>);
          }
          if (value?.outcome?.wg?.can_register && value?.outcome?.wg?.can_handshake) {
            badges.push(<>⚛️</>);
          }
          if (value?.outcome?.wg?.can_register && !value?.outcome?.wg?.can_handshake) {
            badges.push(<span style={{ opacity: 0.2 }}>⚛️</span>);
          }
          if (value?.outcome?.wg?.can_resolve_dns) {
            badges.push(<>🔤</>);
          }
          if (value?.outcome?.wg?.ping_hosts_performance > 0.75 && value?.outcome?.wg?.ping_ips_performance > 0.75) {
            badges.push(<>🛜</>);
          }

          return (
            <CustomWidthTooltip
              placement="left"
              title={
                <Box py={0} my={0} component="pre">
                  <pre>
                    <Ansi>{logLines}</Ansi>
                  </pre>
                </Box>
              }
            >
              <Tooltip placement="right" title={<pre>{JSON.stringify(value, null, 2)}</pre>}>
                <span>{badges}</span>
              </Tooltip>
            </CustomWidthTooltip>
          );
        },
      },
      {
        header: 'Entry Gateway registers Wireguard peer?',
        accessorKey: 'last_probe_result.outcome.wg.can_register',
        Cell: ({ cell }) => (cell.getValue() ? '✅' : '❌'),
      },
      {
        header: 'Entry Gateway does Wireguard handshake?',
        accessorKey: 'last_probe_result.outcome.wg.can_handshake',
        Cell: ({ cell }) => (cell.getValue() ? '✅' : '❌'),
      },
      {
        header: 'Exit Gateway has IPR?',
        accessorKey: 'self_described.ip_packet_router.address',
        Cell: ({ cell }) => (cell.getValue() ? '✅' : null),
      },
      {
        header: 'Exit Gateway has NR',
        accessorKey: 'self_described.network_requester.address',
        Cell: ({ cell }) => (cell.getValue() ? '✅' : null),
      },
      {
        header: 'NR (uses exit policy)',
        accessorKey: 'self_described.network_requester.uses_exit_policy',
        Cell: ({ cell }) => (cell.getValue() ? '✅' : null),
      },
      {
        header: 'Software Version',
        accessorKey: 'self_described.build_information.build_version',
      },
      {
        header: 'Git Commit Hash',
        accessorKey: 'self_described.build_information.commit_sha',
        Cell: ({ cell }) => <code>{cell.getValue<string>()?.slice(0, 6)}</code>,
      },
      {
        header: 'Last Updated',
        accessorKey: 'last_updated_utc',
        Cell: ({ cell }) => <HumanDateTime value={cell.getValue<any>()} />,
      },
      {
        header: 'Last Successful Test Run',
        accessorKey: 'last_testrun_utc',
        Cell: ({ cell }) => <HumanDateTime value={cell.getValue<any>()} />,
      },
      // {
      //   header: 'Name',
      //   accessorKey: 'description.name',
      // }, {
      //   header: 'Description',
      //   accessorKey: 'description.description',
      // }
    ],
    [],
  );
  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'probeScoreCalculated',
          desc: true,
        },
      ],
    },
    data: allGateways,
    enableRowSelection: true, // enable some features
    enableColumnOrdering: false, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
  });

  if (!lastUpdated) {
    return (
      <Box mt={4} display="flex" alignItems="center">
        <CircularProgress />
        <Typography fontSize="larger" ml={2}>
          Please wait while gathering rainbows and sorting kittens... 🌈😼
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {summary && gateways && (
        <Box mt={2} pb={2}>
          <Stack direction="row" spacing={4}>
            <Box>
              <Typography fontWeight={600}>Config scores</Typography>
              <Table>
                <TableBody>
                  <SummaryLine
                    emoji={
                      <Badge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={<Typography fontSize={30}>😀</Typography>}
                        overlap="circular"
                      >
                        🐼
                      </Badge>
                    }
                    item={summary.termsAndConditions}
                    total={gateways.length}
                  />
                  <SummaryLine
                    emoji={configCategories.panda.emoji}
                    item={summary.configScores.pandas}
                    total={gateways.length}
                  />
                  <SummaryLine
                    emoji={configCategories.dolphin.emoji}
                    item={summary.configScores.dolphins}
                    total={gateways.length}
                  />
                  <SummaryLine
                    emoji={configCategories.fox.emoji}
                    item={summary.configScores.foxes}
                    total={gateways.length}
                  />
                  <SummaryLine
                    emoji={configCategories.mouse.emoji}
                    item={summary.configScores.mouses}
                    total={gateways.length}
                  />
                  <SummaryLine emoji="☠️" item={summary.unbonded} total={allGateways.length} hidePercentage />
                </TableBody>
              </Table>
              {gatewayVersions.length && (
                <>
                  <Typography fontWeight={600} mt={3}>
                    Software versions
                  </Typography>
                  <BarChart
                    dataset={gatewayVersions}
                    xAxis={[{ scaleType: 'band', dataKey: 'version', label: 'Versions' }]}
                    series={[{ dataKey: 'count' }]}
                    width={500}
                    height={300}
                    yAxis={[{ label: 'Gateways' }]}
                  />
                </>
              )}
            </Box>
            <Box>
              <Typography fontWeight={600}>Routing scores</Typography>
              <Table>
                <TableBody>
                  <SummaryLine emoji="🥜" item={summary.routingScores.peanuts} total={gateways.length} />
                  <SummaryLine emoji="🍦" item={summary.routingScores.icecreams} total={gateways.length} />
                  <SummaryLine emoji="🍿" item={summary.routingScores.popcorns} total={gateways.length} />
                  <SummaryLine emoji="🍭" item={summary.routingScores.lollies} total={gateways.length} />
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Box>
      )}

      <Box mt={5} py={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {busy ? (
              <Typography>{busyMessage || 'Refreshing...'}</Typography>
            ) : (
              <Button variant="outlined" onClick={refresh}>
                Refresh
              </Button>
            )}
          </Box>
          {lastUpdated && (
            <Stack direction="row" spacing={2}>
              <Typography>Last updated</Typography>
              <Typography>
                <strong>
                  {formatDistanceToNow(lastUpdated, {
                    addSuffix: true,
                  })}
                </strong>
                <br />
                {lastUpdated.toISOString()}
              </Typography>
            </Stack>
          )}
        </Box>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};
