import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["width", "height", "series", "margin", "xAxis", "yAxis", "zAxis", "colors", "dataset", "sx", "title", "desc", "disableAxisListener", "highlightedItem", "onHighlightChange", "plugins", "children"];
import { useChartContainerHooks } from './useChartContainerHooks';
import { useDefaultizeAxis } from './useDefaultizeAxis';
export const useChartContainerProps = (props, ref) => {
  const {
      width,
      height,
      series,
      margin,
      xAxis,
      yAxis,
      zAxis,
      colors,
      dataset,
      sx,
      title,
      desc,
      disableAxisListener,
      highlightedItem,
      onHighlightChange,
      plugins,
      children
    } = props,
    other = _objectWithoutPropertiesLoose(props, _excluded);
  const {
    svgRef,
    chartSurfaceRef,
    xExtremumGetters,
    yExtremumGetters,
    seriesFormatters,
    colorProcessors
  } = useChartContainerHooks(ref, plugins);
  const [defaultizedXAxis, defaultizedYAxis] = useDefaultizeAxis(xAxis, yAxis);
  const drawingProviderProps = {
    width,
    height,
    margin,
    svgRef
  };
  const colorProviderProps = {
    colorProcessors
  };
  const seriesContextProps = {
    series,
    colors,
    dataset,
    seriesFormatters
  };
  const cartesianContextProps = {
    xAxis: defaultizedXAxis,
    yAxis: defaultizedYAxis,
    dataset,
    xExtremumGetters,
    yExtremumGetters
  };
  const zAxisContextProps = {
    zAxis,
    dataset
  };
  const highlightedProviderProps = {
    highlightedItem,
    onHighlightChange
  };
  const chartsSurfaceProps = _extends({}, other, {
    width,
    height,
    ref: chartSurfaceRef,
    sx,
    title,
    desc,
    disableAxisListener
  });
  return {
    children,
    drawingProviderProps,
    colorProviderProps,
    seriesContextProps,
    cartesianContextProps,
    zAxisContextProps,
    highlightedProviderProps,
    chartsSurfaceProps,
    xAxis: defaultizedXAxis,
    yAxis: defaultizedYAxis
  };
};