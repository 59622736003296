import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { api } from './api';
import { HabourMasterHeader } from './Logo';
import { testSemVer } from './scores/configScore';
import { minNymNodeVersion } from './scores';

const boolToEmoji = (value?: boolean) => (value ? '✅' : '❌');

interface MixnodeConfigScoreCategories {
  bonded: boolean;
  blacklisted: boolean;
  nymNodeVersionOk: boolean;
  binaryName?: string;
  nymNodeVersion?: string;
  hasDescribedApi: boolean;
  acceptedTermsAndConditions: boolean;
}

export const Mixnode: React.FC = () => {
  const { mixid } = useParams();
  const [mixnode, setMixnode] = React.useState<any>();

  const mixnodeConfigScore = React.useMemo<MixnodeConfigScoreCategories | null>(() => {
    if (!mixnode) {
      return null;
    }

    const binaryName = mixnode.self_described?.self_described?.build_information?.binary_name;
    const nymNodeVersion = mixnode.self_described?.self_described?.build_information?.build_version;
    const nymNodeVersionOk = testSemVer(nymNodeVersion) && binaryName === 'nym-node';

    return {
      bonded: mixnode.bonded,
      blacklisted: mixnode.blacklisted,
      hasDescribedApi: Boolean(mixnode.self_described?.self_described),
      binaryName,
      nymNodeVersionOk,
      nymNodeVersion,
      acceptedTermsAndConditions: Boolean(
        mixnode.self_described?.self_described?.auxiliary_details?.accepted_operator_terms_and_conditions,
      ),
    };
  }, [mixnode]);

  const refresh = async () => {
    try {
      if (mixid) {
        const newMixnode = await api.mixnode(mixid);
        setMixnode(newMixnode);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting services', e);
    }
  };

  React.useEffect(() => {
    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, [mixid]);

  if (!mixnode) {
    return (
      <Box p={4}>
        <HabourMasterHeader />
        {mixid && (
          <h2>
            🎲️ Mixnode <code>{mixid}</code>
          </h2>
        )}
        <Box mt={4} display="flex" alignItems="center">
          <CircularProgress />
          <Typography fontSize="larger" ml={2}>
            Please have some popcorn 🍿 or peanuts 🥜 while we cook your order...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <HabourMasterHeader />
      {mixnode.description ? (
        <>
          <Box component="h2" mb={0.5}>
            🎲️ {mixnode.description.moniker}
          </Box>
          {mixnode.description.details && (
            <Box mb={2} fontWeight={600}>
              {mixnode.description.details}
            </Box>
          )}
        </>
      ) : (
        <h2>
          🎲️ Mixnode <code>{mixid}</code>
        </h2>
      )}

      {mixnodeConfigScore && (
        <TableContainer sx={{ maxWidth: 400, mt: 2 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Bonded</TableCell>
                <TableCell>{boolToEmoji(mixnodeConfigScore.bonded)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Not blacklisted</TableCell>
                <TableCell>{boolToEmoji(!mixnodeConfigScore.blacklisted)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Node API is reachable by Nym API</TableCell>
                <TableCell>{boolToEmoji(mixnodeConfigScore.hasDescribedApi)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Accepted Operator T&Cs</TableCell>
                <TableCell>{boolToEmoji(mixnodeConfigScore.acceptedTermsAndConditions)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>
                  Runs nym-node version &gt;= <code>{minNymNodeVersion}</code>
                </TableCell>
                <TableCell>{boolToEmoji(mixnodeConfigScore.nymNodeVersionOk)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ pl: 3 }}>Binary name</TableCell>
                <TableCell>
                  {mixnodeConfigScore.binaryName && <code>{mixnodeConfigScore.binaryName}</code>}
                  {mixnodeConfigScore.nymNodeVersion && <Typography>{mixnodeConfigScore.nymNodeVersion}</Typography>}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box mt={4}>
        Last updated from directory&nbsp;
        <strong>{formatDistanceToNow(mixnode.last_updated_utc, { addSuffix: true })}</strong>
        &nbsp;at&nbsp;
        {mixnode.last_updated_utc.toISOString()}
      </Box>

      <Box>
        <Link href={`https://explorer.nymtech.net/network-components/mixnode/${mixid}`} target="_blank">
          View in explorer
        </Link>
      </Box>

      <hr />
      <pre>{JSON.stringify(mixnode, null, 2)}</pre>
    </Box>
  );
};
