import _extends from "@babel/runtime/helpers/esm/extends";
export const normalizeAxis = (axis, dataset, axisName) => {
  return axis?.map(axisConfig => {
    const dataKey = axisConfig.dataKey;
    if (dataKey === undefined || axisConfig.data !== undefined) {
      return axisConfig;
    }
    if (dataset === undefined) {
      throw Error(`MUI X Charts: ${axisName}-axis uses \`dataKey\` but no \`dataset\` is provided.`);
    }
    return _extends({}, axisConfig, {
      data: dataset.map(d => d[dataKey])
    });
  }) ?? [];
};