/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { HabourMasterHeader } from './Logo';
import { Services } from './Services';
import { MixFetchEntryGateways } from './MixFetchEntryGateways';
import { Gateways } from './Gateways';
import { Mixnodes } from './Mixnodes';
import { api } from './api';
import { SummaryCharts } from './SummaryCharts';
import { MixnetStatsCharts, MixnetStakingCharts } from './MixnetStatsCharts';
import { Charts } from './Charts';

export const Home: React.FC = () => {
  const [summary, setSummary] = React.useState<any>(null);
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const refresh = async () => {
    try {
      const newSummary = await api.summary();
      setSummary(newSummary);
    } catch (e) {
      console.error('Failed to get summary', e);
    }
  };

  React.useEffect(() => {
    refresh();
    const timer = setInterval(refresh, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box p={4}>
      <HabourMasterHeader />

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={<h2>Gateways {summary && <>({summary.gateways.bonded.count})</>}</h2>} value="1" />
            <Tab label={<h2>Mixnodes {summary && <>({summary.mixnodes.bonded.count})</>}</h2>} value="2" />
            <Tab label={<h2>Gateways for mixFetch</h2>} value="3" />
            <Tab label={<h2>SOCKS5 Network Requesters</h2>} value="4" />
            <Tab label={<h2>Charts</h2>} value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Gateways />
        </TabPanel>
        <TabPanel value="2">
          <Mixnodes />
        </TabPanel>
        <TabPanel value="3">
          <MixFetchEntryGateways />
        </TabPanel>
        <TabPanel value="4">
          <Services />
        </TabPanel>
        <TabPanel value="5">
          <Charts />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
